<template>
	<div class="about">
		<div class="first-box">
			<div class="content-cls">
				<div class="main-title white-cls">
					<div class="big-title">关于我们</div>
					<div class="small-title">— about Us —</div>
				</div>
				<div class="second-title">厦门飞飞鱼供应链管理有限公司</div>
				<div class="detail-cls">厦门飞飞鱼供应链管理有限公司是全球性的S2B2C柔性供应链赋能平台，具有零库存、低成本、快反应、高定制的优势，致力于为B端实现数据化和智能化服务，同时提供B端对C端的可视化服务。公司拥有大型工厂，可提供包袋、家居、鞋服、户外、3C周边等产品的柔性化个性定制。平台以优质化货源、智能化服务、人性化操作，逐步成为消费者、商家、供应商的优选。</div>
			</div>
			<van-image class="img-bg-cls" :src="require('../assets/about/top_bg.jpg')" fit="cover" lazy-load />
		</div>

		<div class="second-box">
			<div class="main-title">
				<div class="big-title">品牌故事</div>
				<div class="small-title">— Brand story —</div>
			</div>
			<div class="content-cls">
				<div class="second-title">飞飞鱼文创</div>
				<div class="detail-cls">飞飞鱼文创是由厦门飞飞鱼供应链管理有限公司提出的“飞飞鱼+”文创项目，飞飞鱼文创旨在通过自身强大的开发能力与运营能力，推动国内文创产业多元化发展，提高行业服务质量；孵化IP，扩大IP的影响力与附加值；助力中小品牌成长，服务IP衍生市场，提升商家的综合能力。</div>
				<div class="img-list">
					<img class="img-cls" src="../assets/about/brand_1.png" alt="">
					<img class="img-cls" src="../assets/about/brand_2.png" alt="">
					<img class="img-cls" src="../assets/about/brand_3.png" alt="">
					<img class="img-cls" src="../assets/about/brand_4.png" alt="">
				</div>
			</div>
		</div>

		<div class="third-box">
			<div class="main-title">
				<div class="big-title">企业文化</div>
				<div class="small-title">— company culture —</div>
			</div>
			<div class="content-cls img-title-list">
				<div class="item-cls">
					<img class="img-cls" src="../assets/about/culture_1.png" alt="">
					<div class="title-cls">企业使命</div>
					<div class="msg-cls">引领柔性供应链走向IP文创道路</div>
				</div>
				<div class="item-cls">
					<img class="img-cls" src="../assets/about/culture_2.png" alt="">
					<div class="title-cls">企业核心价值观</div>
					<div class="msg-cls">利他、创新、共赢</div>
				</div>
				<div class="item-cls">
					<img class="img-cls" src="../assets/about/culture_3.png" alt="">
					<div class="title-cls">企业愿景</div>
					<div class="msg-cls">打造全球性文创供应链赋能平台</div>
				</div>
			</div>
		</div>

		<div class="fourth-box">
			<div class="main-title">
				<div class="big-title">我们的团队</div>
				<div class="small-title">— our team —</div>
			</div>
			<div class="content-cls img-box">
				<img class="icon-cls" src="../assets/about/icon_team.png" alt="">
				<div class="detail-cls">我们是一支年轻又具有创意的团队，从产品研发、创意设计、技术<br>
					开发、市场营销到客户服务，不断打破固有思维、鼓励创新，精心打磨优秀IP文创产品，助力更多文创品牌走向市场化。</div>
			</div>
			<van-image class="img-bg-cls" :src="require('../assets/about/our_team.jpg')" fit="cover" lazy-load />
		</div>

		<div class="fifth-box">
			<div class="main-title">
				<div class="big-title">我们的目标</div>
				<div class="small-title">— Our goal —</div>
			</div>
			<div class="content-cls">利用柔性供应链平台为全球各类IP品牌市场化服务，通过品牌与产品的创意、设计、生产、营销，触达更多消费人群；<br>
				为设计师、原创者、艺术家提供一个设计平台，通过飞飞鱼+独有的个性定制平台，让其创意作品得以转化为文创产品面向市场；<br>
				为商家深耕优质作品，一键直达消费者，不断优化平台的功能和服务质量，致力生产高颜值、高品质、高性价比的文创产品；<br>
				依托飞飞鱼成熟的海外销售体系，帮助原创国风IP衍生品打开海外市场，走向全球。</div>
		</div>

		<div class="sixth-box">
			<div class="main-title">
				<div class="big-title">我们的服务</div>
				<div class="small-title">— our service —</div>
			</div>
			<div class="content-cls service-list">
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_1.png" alt="">
					</div>
					<div class="title-cls">一件生产、一件代发</div>
					<div class="msg-cls">供应链快速反应释放，商家<br>库存和资金压力</div>
				</div>
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_2.png" alt="">
					</div>
					<div class="title-cls">个性化定制</div>
					<div class="msg-cls">智能设计器辅助<br>简单易操作</div>
				</div>
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_3.png" alt="">
					</div>
					<div class="title-cls">强大的技术支持</div>
					<div class="msg-cls">从设计到生产、发货，全程智能化管理<br>真正实现零沟通，高效率</div>
				</div>
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_4.png" alt="">
					</div>
					<div class="title-cls">专业设计团队</div>
					<div class="msg-cls">提供专业优质产品设计开发并保持每<br>月更新，满足多元化需求</div>
				</div>
			</div>
		</div>

		<div class="seventh-box">
			<div class="main-title">
				<div class="big-title">现有IP合作</div>
				<div class="small-title">— Existing IP cooperation —</div>
			</div>
			<div class="content-cls ip-list">
				<div class="item-cls">
					<img class="img-cls" src="../assets/about/ip_1.jpg" alt="">
					<img class="img-cls" src="../assets/about/ip_2.jpg" alt="">
					<img class="img-cls" src="../assets/about/ip_3.jpg" alt="">
					<img class="img-cls" src="../assets/about/ip_4.jpg" alt="">
					<img class="img-cls" src="../assets/about/ip_5.jpg" alt="">
					<div class="more-box">
						<div class="more-msg">奇先生妙小姐、哆啦A梦、粉红豹、梵高、<br>与多个海内外知名IP接洽中</div>
					</div>
				</div>
			</div>
		</div>

		<div class="eighth-box">
			<div class="main-title">
				<div class="big-title">官方渠道</div>
				<div class="small-title">— Official channel —</div>
			</div>
			<div class="content-cls channel-list">
				<div class="item-cls xcx-cls">
					<img class="img-cls" src="../assets/about/icon_xcx.jpg" alt="">
					<div class="channe-cls">小程序</div>
					<div class="name-cls">飞飞鱼文创</div>
				</div>
				<div class="item-cls wx-cls">
					<img class="img-cls" src="../assets/about/icon_wx.jpg" alt="">
					<div class="channe-cls">服务号</div>
					<div class="name-cls">飞飞鱼Fiifish</div>
				</div>
				<div class="item-cls dy-cls">
					<img class="img-cls" src="../assets/about/icon_dy.jpg" alt="">
					<div class="channe-cls">抖音</div>
					<div class="name-cls">飞飞鱼历险记</div>
				</div>
				<div class="item-cls tb-cls">
					<img class="img-cls" src="../assets/about/icon_tb.jpg" alt="">
					<div class="channe-cls">淘宝</div>
					<div class="name-cls">飞飞鱼文创</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		}
	}
</script>

<style scoped lang="scss">
	.content-cls {
		margin: 0 auto;
		width: 100%;
	}

	.second-title {
		margin: 0.8rem 0 1.2rem;
		font-size: 1.4rem;
		font-weight: 400;
	}

	.title-cls {
		font-size: 1.4rem;
	}

	.msg-cls {
		font-size: 1rem;
		color: #666;
	}

	.detail-cls {
		font-size: 0.8rem;
		line-height: 1.4rem;
		font-weight: 400;
		padding: 0 1rem;
	}

	.first-box {
		height: 20rem;
		position: relative;
		text-align: center;
		color: #fff;

		.content-cls {
			width: 100%;
			position: absolute;
			z-index: 1;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		
		.img-bg-cls{
			height: 20rem;
		}

	}

	.second-box {
		padding: 3rem 0 1rem;

		.img-list {
			margin-top: 3rem;

			.img-cls {
				margin: 0 1rem 1rem 0;
				height: 10rem;
				width: 10rem;
			}

			.img-cls:nth-child(2n) {
				margin-right: 0;
			}
		}
	}

	.third-box {
		padding: 3rem 0;
		background: #fafafa;

		.img-title-list {
			margin-top: 2rem;

			.item-cls {
				display: inline-block;
				height: 18rem;
				width: 8rem;
				text-align: center;
				background: #fff;
				border-radius: 1rem;
				vertical-align: top;
				margin: 0 0 1rem 1rem;
				transition: all .3s;
				padding: 1rem;
			}

			.item-cls:first-child {
				margin-left: 0;
			}
			
			.img-cls {
				margin-top: 1rem;
			}

			.title-cls {
				margin: 1.5rem 0 1rem;
			}
		}
	}

	.fourth-box {
		padding-top: 3rem;
		position: relative;
		text-align: center;
		color: #fff;

		.main-title {
			padding-bottom: 2rem;
		}

		.content-cls {
			width: 100%;
			position: absolute;
			z-index: 1;
			left: 50%;
			transform: translateX(-50%);
		}

		.icon-cls {
			width: 8rem;
			margin: 2rem 0 1rem;
		}
		
		.img-bg-cls{
			width: 100%;
			height: 16rem;
		}
	}

	.fifth-box {
		padding: 3rem 0;

		.content-cls {
			color: #999;
			line-height: 1.6rem;
			margin-top: 2rem;
			font-size: 1rem;
			padding: 0 1rem;
			box-sizing: border-box;
		}
	}

	.sixth-box {
		background: #fafafa;
		padding: 3rem 0;

		.content-cls {
			margin-top: 2rem;
		}

		.item-cls {
			width: 10rem;
			text-align: center;
			display: inline-block;
			margin: 0 0 2rem 1rem;
			vertical-align: top;

			.img-border {
				background: #fff;
				height: 10rem;
				width: 10rem;
				line-height: 10rem;
				border-radius: 50%;
				display: inline-block;
				position: relative;

				.img-cls {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.title-cls {
				margin: 1rem 0;
			}

			.msg-cls {
				font-size: 0.6rem;
				color: #999;
			}
		}

		.item-cls:first-child {
			margin-left: 0;
		}

	}

	.seventh-box {
		padding: 3rem 0;

		.ip-list {
			margin-top: 2rem;
			font-size: 0;
			border-radius: 1rem;
			overflow: hidden;

			.img-cls {
				width: 10.5rem;
				height: 7.6rem;
				vertical-align: top;
			}

			.more-box {
				vertical-align: top;
				width: 10.5rem;
				height: 7.6rem;
				text-align: center;
				background: #fafafa;
				display: inline-block;
				position: relative;
			}

			.more-msg {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 90%;
				font-size: 0.8rem;
				color: #666;
				line-height: 1.6rem;
			}
		}
	}

	.eighth-box {
		padding-bottom: 1rem;

		.channel-list {
			margin-top: 2rem;
		}

		.item-cls {
			height: 10.5rem;
			width: 10rem;
			text-align: center;
			display: inline-block;
			margin-right: 1rem;
			border-radius: 1rem;
			margin-bottom: 4rem;
		}

		.item-cls:nth-child(2n) {
			margin-right: 0;
		}
		
		.item-cls:hover .img-cls{
			transform: scale(1.05);
		}
		
		.img-cls{
			height: 5rem;
			width: 5rem;
			margin-top: 2rem;
		}

		.channe-cls {
			font-size: 0.8rem;
			color: #666;
			margin: 1rem 0;
		}

		.name-cls {
			font-size: 1.3rem;
			padding-top: 1rem;
		}

		.xcx-cls {
			background: #F8F8FF;
		}

		.wx-cls {

			background: #F7FFF9;
		}

		.dy-cls {
			background: #EDFFFE;
		}

		.tb-cls {

			background: #FFFAF8;
		}
	}
</style>
