<template>
	<div class="about">
		<div class="first-box">
			<img class="img-bg-cls" v-if="bannerInfo" :src="bannerInfo[0]" alt="">
			<div class="content-cls about-us">
				<div class="main-title">
					<div class="big-title">关于我们</div>
					<div class="small-title">— about Us —</div>
				</div>
				<div class="introduce">
					<img src="../assets/about/eg_pic_1.png">
					<div class="text">
						<div class="sec-title">厦门飞飞鱼供应链管理有限公司</div>
						<div class="detail-cls">厦门飞飞鱼供应链管理有限公司是全球性的S2B2C柔性供应链赋能平台，具有零库存、低成本、快反应、高定制的优势，致力于为B端实现数据化和智能化服务，同时提供B端对C端的可视化服务。公司拥有大型工厂，可提供包袋、家居、鞋服、户外、3C周边等产品的柔性化个性定制。平台以优质化货源、智能化服务、人性化操作，逐步成为消费者、商家、供应商的优选。</div>
					</div>
				</div>
			</div>
		</div>

		<div class="second-box">
			<div class="main-title">
				<div class="big-title">品牌故事</div>
				<div class="small-title">— Brand story —</div>
			</div>
			<div class="content-cls">
				<div class="second-title">飞飞鱼文创</div>
				<div class="detail-cls">飞飞鱼文创是由厦门飞飞鱼供应链管理有限公司提出的“飞飞鱼+”文创项目，飞飞鱼文创旨在通过自身强大的开发能力与运营能力，推动国内文创产业多元化发展，提高行业服务质量；孵化IP，扩大IP的影响力与附加值；助力中小品牌成长，服务IP衍生市场，提升商家的综合能力。</div>
				<div class="img-list">
					<img class="img-cls" src="../assets/about/brand_1.png" alt="">
					<img class="img-cls" src="../assets/about/brand_2.png" alt="">
					<img class="img-cls" src="../assets/about/brand_3.png" alt="">
					<img class="img-cls" src="../assets/about/brand_4.png" alt="">
				</div>
			</div>
		</div>

		<div class="third-box">
			<div class="main-title">
				<div class="big-title">企业文化</div>
				<div class="small-title">— company culture —</div>
			</div>
			<div class="content-cls img-title-list">
				<div class="item-cls">
					<img class="img-cls" src="../assets/about/culture_1.png" alt="">
					<div class="title-cls">企业使命</div>
					<div class="msg-cls">引领柔性供应链走向IP文创道路</div>
				</div>
				<div class="item-cls">
					<img class="img-cls" src="../assets/about/culture_2.png" alt="">
					<div class="title-cls">企业核心价值观</div>
					<div class="msg-cls">诚信、利他、创新、共赢</div>
				</div>
				<div class="item-cls">
					<img class="img-cls" src="../assets/about/culture_3.png" alt="">
					<div class="title-cls">企业愿景</div>
					<div class="msg-cls">打造全球性文创供应链赋能平台</div>
				</div>
			</div>
		</div>

		<div class="sixth-box">
			<div class="main-title">
				<div class="big-title">我们的服务</div>
				<div class="small-title">— our service —</div>
			</div>
			<div class="content-cls service-list">
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_1.png" alt="">
					</div>
					<div class="title-cls">一件生产、一件代发</div>
					<div class="msg-cls">供应链快速反应，释放<br>商家库存和资金压力</div>
				</div>
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_2.png" alt="">
					</div>
					<div class="title-cls">个性化定制</div>
					<div class="msg-cls">智能设计器辅助<br>简单易操作</div>
				</div>
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_3.png" alt="">
					</div>
					<div class="title-cls">强大的技术支持</div>
					<div class="msg-cls">从设计到生产、发货（支持FBA/FBM），全程智能化管理真正实现零沟通，高效率</div>
				</div>
				<div class="item-cls">
					<div class="img-border">
						<img class="img-cls" src="../assets/about/service_4.png" alt="">
					</div>
					<div class="title-cls">专业设计团队</div>
					<div class="msg-cls">提供专业优质产品设计开发，<br>满足多元化需求</div>
				</div>
			</div>
		</div>
		<div class="seventh-box">
			<div class="main-title">
				<div class="big-title">商务伙伴</div>
				<div class="small-title">—   Business Partner   —</div>
			</div>
			<div class="partner-box">
				<div class="partner-list move">
					<div class="partner-item" v-for="(item, index) in initData.business" :key="index" @click="goWeb(item.turn_url)">
						<img class="partner-img" :src="item.img_id" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="eighth-box">
			<div class="main-title">
				<div class="big-title">官方渠道</div>
				<div class="small-title">— Official channel —</div>
			</div>
			<div class="content-cls channel-list">
				<div class="item-cls xcx-cls">
					<img class="img-cls" src="../assets/about/icon_xcx.jpg" alt="">
					<div class="channe-cls">小程序</div>
					<div class="name-cls">飞飞鱼文创</div>
				</div>
				<div class="item-cls wx-cls">
					<img class="img-cls" src="../assets/about/icon_wx.jpg" alt="">
					<div class="channe-cls">服务号</div>
					<div class="name-cls">飞飞鱼Fiifish</div>
				</div>
				<div class="item-cls dy-cls">
					<img class="img-cls" src="../assets/about/icon_dy.jpg" alt="">
					<div class="channe-cls">抖音</div>
					<div class="name-cls">飞飞鱼历险记</div>
				</div>
				<div class="item-cls tb-cls">
					<img class="img-cls" src="../assets/about/icon_tb.jpg" alt="">
					<div class="channe-cls">淘宝</div>
					<div class="name-cls">飞飞鱼文创</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		data() {
			return {

			}
		},
		computed: {
			...mapState({
				initData: state => state.initData, //初始化数据
				bannerInfo: state => state.bannerInfo[8], // banner图
			}),
		},
		methods: {
			// 跳转外部链接
			goWeb(url) {
				if (url) {
					if (url.indexOf('http') == -1) {
						url = 'https://' + url
					}
					window.open(url, '_blank');
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.content-cls {
		margin: 0 auto;
		width: 1260px;
	}

	.second-title {
		margin: 30px 0 50px;
		font-size: 24px;
		font-weight: 400;
	}

	.title-cls {
		font-size: 24px;
	}

	.msg-cls {
		font-size: 18px;
		color: #666;
	}

	.detail-cls {
		font-size: 18px;
		line-height: 30px;
		font-weight: 400;
	}

	.first-box {
		position: relative;
		text-align: center;
		padding-bottom: 80px;
		.img-bg-cls{
			width: 100%;
		}
		.main-title{
			margin: 90px 0 80px 0;
		}
		.introduce{
			display: flex;
			align-items: center;
			.text{
				text-align: left;
				margin-left: 60px;
				color: #333333;
				.sec-title{		
					font-size: 24px;
					&::after{
						margin:23px 0;
						display: block;
						content: '';
						width: 29px;
						height: 2px;
						background: #333333;

					}
				}
			}
		}

	}

	.second-box {
		padding: 90px 0 80px 0;
		background: #FAFAFA;
		.img-list {
			margin-top: 60px;

			.img-cls {
				margin-left: 60px;
				height: 270px;
				width: 270px;
				box-shadow: 0px 0px 15px 0px rgba(13,4,8,0.0600);
				border-radius: 30px;
			}

			.img-cls:first-child {
				margin-left: 0;
			}
		}
	}

	.third-box {
		padding: 90px 0;
		.img-title-list {
			margin-top: 80px;

			.item-cls {
				display: inline-block;
				height: 370px;
				width: 360px;
				text-align: center;
				background: #fff;
				border-radius: 20px;
				vertical-align: top;
				margin-left: 80px;
				transition: all .3s;
			}

			.item-cls:first-child {
				margin-left: 0;
			}
			
			.item-cls:hover{
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
			}
			
			.img-cls {
				margin-top: 50px;
			}
			
			.item-cls:hover .img-cls{
				transform: scale(1.05);
			}

			.title-cls {
				margin: 50px 0 25px;
			}
		}
	}

	.sixth-box {
		background: #fafafa;
		padding: 70px 0;

		.content-cls {
			margin-top: 69px;
		}

		.item-cls {
			width: 240px;
			text-align: center;
			display: inline-block;
			margin-left: 100px;

			.img-border {
				background: #fff;
				height: 180px;
				width: 180px;
				line-height: 180px;
				border-radius: 50%;
				display: inline-block;
				position: relative;
				box-shadow: 0px 0px 15px 0px rgba(13,4,8,0.0600);

				.img-cls {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.title-cls {
				margin: 25px 0;
			}

			.msg-cls {
				font-size: 12px;
				color: #999;
			}
		}

		.item-cls:first-child {
			margin-left: 0;
		}
		
		.item-cls:hover .img-border {
			box-shadow: 0px 0px 6px 0px rgba(64, 158, 255, 0.5);
		}

	}
	.seventh-box{
		padding: 90px 0 70px 0;
		.partner-box {
			width: 1360px;
			margin: 60px auto 0;

			.partner-list {
				display: flex;
				flex-wrap: wrap;
			}
			.partner-item {
				margin: 0 31px 15px 31px;
				cursor: pointer;
			}

			.partner-img {
				width: 158px;
				height: 60px;
				object-fit:cover;
				transition: all .3s;
			}
			
			.partner-img:hover{
				transform: scale(1.05);
			}
		}
	}
	.eighth-box {
		padding-top:20px;
		padding-bottom: 90px;
		background: #fafafa;

		.channel-list {
			margin-top: 69px;
		}

		.item-cls {
			height: 270px;
			width: 260px;
			text-align: center;
			display: inline-block;
			margin-left: 60px;
			border-radius: 20px;
		}

		.item-cls:first-child {
			margin-left: 0;
		}
		
		.item-cls:hover{
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
		}
		
		.item-cls:hover .img-cls{
			transform: scale(1.05);
		}
		
		.img-cls{
			height: 90px;
			width: 90px;
			margin-top: 50px;
		}

		.channe-cls {
			font-size: 16px;
			color: #666;
			margin: 20px 0;
		}

		.name-cls {
			font-size: 24px;
		}

		.xcx-cls {
			background: #F8F8FF;
		}

		.wx-cls {

			background: #F7FFF9;
		}

		.dy-cls {
			background: #EDFFFE;
		}

		.tb-cls {

			background: #FFFAF8;
		}
	}
</style>
